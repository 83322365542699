<template>
  <div id="section">
    <div id="content">
      <div class="header">
        <PageTitle title="Histórico de registros" />
        <div class="header-info">
          <div class="info">
            <label>Tipo de Material</label>
            <br />
            <span>{{ material ? material.tipoMaterialGenerico : '' }}</span>
          </div>
          <div class="info">
            <label>Descrição</label>
            <br />
            <span>{{ material ? material.descricao : '' }}</span>
          </div>
          <div class="info">
            <label>Peças</label>
            <br />
            <span>{{ material ? material.pecas : '' }}</span>
          </div>
        </div>
      </div>

      <!-- Filtros -->
      <b-row cols="10" class="ml-5 mb-2">
        <div>
          <b-button
            class=" btn secondary-contained-button text-white mr-1"
            @click="searchFilters()"
          >
            Pesquisar
          </b-button>
          <b-button
            class=" btn secondary-contained-button text-white mr-1"
            @click="visible = !visible"
          >
            Todos os filtros
          </b-button>
          <b-button
            @click="clearFilters"
            class="btn secondary-contained-button text-white align-self-center"
          >
            Limpar Filtros
          </b-button>
        </div>
      </b-row>
      <b-row class="ml-5">
        <b-col sm="2">
          <label>Unidade</label>
          <GenericSelect
            name="unidade"
            ref="select-id_unidade"
            labelKey="nome"
            route="unidade"
            v-model="filters.id_unidade"
            @input="item => addFilter(item, 'unidade')"
          />
        </b-col>
        <b-col sm="2">
          <label>Área</label>
          <GenericSelect
            name="area"
            labelKey="nome"
            route="subSetor"
            v-model="filters.id_sub_setor"
            ref="select-id_sub_setor"
            @input="item => selectedSubSetor(item)"
          />
        </b-col>
        <b-col sm="2">
          <label
            v-b-tooltip.hover.bottom="'Necessário escolher unidade e área.'"
          >
            Processo
          </label>
          <GenericSelect
            name="tipoProcesso"
            labelKey="nome"
            route="tipoProcesso"
            v-model="filters.id_tipo_processo"
            ref="select-id_tipo_processo"
            :customFilters="customFilters"
          />
        </b-col>

        <b-col sm="3">
          <label>Data</label>
          <RangeDatePicker
            id="seletor-de-data"
            :initDate="initDate"
            :endDate="endDate"
            :isBusy="loading"
            @selected="rangeDateSelected"
          />
        </b-col>
      </b-row>

      <!-- Filtros laterais -->
      <b-col :class="{ 'visible-filters': visible }" class="filters-sidebar">
        <div style="margin-top: 20%; padding-right: 3%;">
          <h3>Filtros</h3>

          <label v-b-tooltip.hover.bottom="'Digite o protocolo inteiro'">
            Protocolo
          </label>
          <b-form-input
            @input="item => addFilter(item, 'protocolo')"
            placeholder="Protocolo inteiro"
            v-model="filters.protocolo"
          >
          </b-form-input
          ><br />

          <label
            v-b-tooltip.hover.bottom="
              'A busca não distingue maiúscula de minúscula, mas acentos sim.'
            "
          >
            Usuário
          </label>
          <b-form-input
            placeholder="Insira um trecho exato do nome"
            v-model.trim="filters.nome_usuario"
          >
          </b-form-input
          ><br />
        </div>
      </b-col>

      <div v-if="loading" class="d-flex justify-content-center">
        <b-spinner variant="secondary" label="Spinning" />
      </div>

      <div v-else class="body-content">
        <b-table
          :fields="tableFields"
          :items="processosCiclo"
          striped
          class="mt-3 process-table-content"
          responsive
          show-empty
          empty-text="Não existem processos para esse material Sem QR code"
          :per-page="withoutPagination ? null : perPage"
          :current-page="withoutPagination ? null : currentPage"
        >
          <template #cell(abortar)="{ item }">
            <b-button
              class="custom-print-button"
              variant="outline-dark"
              :disabled="!verifyAbortPermission()"
              @click="showModal(item)"
            >
              <trash-2-icon class="w20px" />
            </b-button>
          </template>
          <template #cell(imprimir)="{ item }">
            <GenericPrint
              tipo="processo"
              :data="{
                createdAt: item.createdAt,
                id_material_generico,
                protocolo: item.siclaProtocolo,
              }"
            />
          </template>
        </b-table>

        <div>
          Mostrando resultados {{ perPage * currentPage - perPage + 1 }} a
          {{ perPage * currentPage }} de um total de <strong>{{ rows }}</strong
          >.
        </div>

        <b-pagination
          v-bind:class="{
            'd-none': withoutPagination
          }"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table"
          align-content="right"
          class="float-right"
        ></b-pagination>
      </div>

      <Modal
        id="modalAbortoProcesso"
        ref="modalAbortoProcesso"
        title="Abortar processo"
        hide-footer
        no-close-on-backdrop
      >
        <AbortoProcessoModal :item="processoCicloSelected" />
      </Modal>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import GenericSelect from '@/components/Form/GenericSelect';
import RangeDatePicker from '@/components/Form/RangeDatePicker';

import GenericApi from '@/services/genericRequest';

import servicesMaterialGenerico from '@/services/materialGenerico';

import GenericPrint from '@/components/Utils/GenericPrint';
import PageTitle from '@/templates/PageTitle';
import Modal from '@/components/Utils/Modal';
import AbortoProcessoModal from './components/AbortoProcessoModal';
import LoginService from '@/services/login';

export default {
  components: {
    PageTitle,
    Modal,
    AbortoProcessoModal,
    GenericPrint,
    GenericSelect,
    RangeDatePicker,
  },

  async mounted() {
    this.material = await servicesMaterialGenerico.filterMateriaisGenericosById(
      parseInt(this.id_material_generico, 10),
    );

    this.rangeDateSelected(moment().subtract(7, 'days'), moment());
    await this.searchFilters();

    this.loading = false;
  },

  data() {
    return {
      customFilters: { id_tipo_processo: [] },
      tipoProcesso: null,
      visible: false,
      initDate: null,
      endDate: null,
      loading: true,
      filters: {},
      id_material_generico: this.$route.params.id,
      withoutPagination: false,
      perPage: 10,
      currentPage: 1,
      material: this.$route.params.materialGenerico,
      processoCicloSelected: {},
      processosCiclo: [],
      qt_materiais_imprimir: 1,
      tipoRelatorio: 'Relatorio',
      relatorioData: {},
      tableFields: [
        {
          key: 'unidade.nome',
          label: 'Unidade',
          sortable: false,
        },
        {
          key: 'area.nome',
          label: 'Área',
          sortable: false,
        },
        {
          key: 'tipoProcesso.nome',
          label: 'Processo',
          sortable: false,
        },
        {
          key: 'siclaProtocolo',
          label: 'Protocolo',
          sortable: false,
        },
        {
          key: 'siclaProtocoloSaida',
          label: 'Protocolo de saída',
          sortable: false,
        },
        {
          key: 'quantidade',
          label: 'Quantitativo',
          sortable: false,
        },
        {
          key: 'data',
          label: 'Data - Hora',
          sortable: false,
        },
        {
          key: 'usuario.nome',
          label: 'Usuário',
          sortable: false,
        },
        {
          key: 'abortar',
          label: 'Abortar',
          sortable: false,
        },
        {
          key: 'imprimir',
          label: 'Imprimir',
          sortable: false,
        },
      ],
    };
  },
  computed: {
    rows() {
      return this.processosCiclo.length;
    },
    ...mapState({
      genericData: (state) => state.genericData,
    }),
  },

  methods: {
    selectedSubSetor(item) {
      this.addFilter(item, 'subSetor');
      if (item) {
        this.customFilters = {
          id_tipo_processo: this.genericData.subSetor[item - 1].tipoProcesso.map((tp) => tp.id_tipo_processo),
        };
      } else {
        this.customFilters = {
          id_tipo_processo: this.genericData.subSetor.forEach((ss) => ss.tipoProcesso.map((tp) => tp.id_tipo_processo)),
        };
      }
      return this.customFilters;
    },
    showModal(item) {
      this.processoCicloSelected = { ...item, ...this.material };
      this.$refs.modalAbortoProcesso.show();
    },

    async clearFilters() {
      this.value = null;
      this.loading = true;
      this.rangeDateSelected(moment().subtract(4, 'week'), moment());
      this.filters = { endDate: this.endDate, initDate: this.initDate };
      this.processosCiclo = await GenericApi.getAllWithoutPagination(
        { id: this.id_material_generico },
        'processoCiclo/listProcessoCiclo/materialGenerico',
      );
      this.loading = false;
    },

    rangeDateSelected(initDate, endDate) {
      // eslint-disable-next-line no-underscore-dangle
      if (moment()._d >= initDate._d) {
        this.initDate = initDate;
      } else {
        this.initDate = moment().subtract(1, 'days');
      }
      // eslint-disable-next-line no-underscore-dangle
      if (moment()._d >= endDate._d) {
        this.endDate = endDate;
      } else {
        this.endDate = moment();
      }
      this.addFilter(null, 'date');
    },

    addFilter(item, tipo) {
      const filtersAtuais = { id: this.id_material_generico };
      if (tipo === 'date' || (this.initDate && this.endDate)) {
        filtersAtuais.initDate = this.initDate.format();
        filtersAtuais.endDate = this.endDate.format();
      } else {
        if (tipo === 'unidade' || this.filters.id_unidade) {
          if (tipo === 'unidade' && item !== null) {
            filtersAtuais.id_unidade = item;
          } else if (this.filters.id_unidade) {
            filtersAtuais.id_unidade = this.filters.id_unidade;
          } else {
            delete filtersAtuais.id_unidade;
            delete this.filters.id_unidade;
          }
        }
        if (tipo === 'subSetor' || this.filters.id_sub_setor) {
          if (tipo === 'unidade' && item) {
            filtersAtuais.id_sub_setor = item;
          } else if (this.filters.id_sub_setor) {
            filtersAtuais.id_sub_setor = this.filters.id_sub_setor;
          } else {
            delete filtersAtuais.id_sub_setor;
            delete this.filters.id_sub_setor;
          }
        }
        if (tipo === 'processo') filtersAtuais.id_tipo_processo = item;
        // if (tipo === 'protocolo' || this.filters.protocolo) {
        //   const existentes = this.processosCiclo.find(
        //     (processo) => processo.siclaProtocolo === item,
        //   );
        // }
        if (tipo === 'usuario') filtersAtuais.nome_usuario = item;
      }
      this.filters = Object.assign(this.filters, filtersAtuais);
      return this.filters;
    },

    async searchFilters() {
      if (!this.initDate || !this.endDate) {
        this.rangeDateSelected(moment().subtract(2, 'week'), moment());
        this.filters.initDate = this.initDate;
        this.filters.endDate = this.endDate;
      }
      if (!this.filters.id_sub_setor) delete this.filters.id_sub_setor;
      this.loading = true;
      this.processosCiclo = await GenericApi.getAllWithoutPagination(
        { id: this.id_material_generico, ...this.filters },
        'processoCiclo/listProcessoCiclo/materialGenerico',
      );
      this.loading = false;
    },

    verifyAbortPermission() {
      return LoginService.verifyPermissions(['rd_encerrar_ciclo']);
    },
  },
};
</script>

<style scoped>
.filters-sidebar {
  z-index: 100;
  position: fixed;
  min-height: 100%;
  right: -22%;
  top: 64px;
  background-color: white;
  width: 22%;
  transition: 0.5s;
}
.filters-sidebar.visible-filters {
  right: 0;
}
.header {
  background-color: #fff;
  margin-top: -2rem;
  padding: 2rem 4rem;
  border-bottom: 1px solid #cfd1e6;
  margin-bottom: 3rem;
}
.header-info {
  display: flex;
}
.info label {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #7e829f;
}
.info {
  margin-right: 5rem;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #35384d;
}
.body-content {
  padding: 0 3rem;
}
.process-table-content {
  background-color: #fff;
  padding: 3rem 1rem;
  border-radius: 5px;
}
.body-print-drop-down {
  padding: 0.5rem;
  text-align: center;
}
.body-print-drop-down span {
  font-size: 15px;
  color: #5e627a;
}
.custom-print-button {
  color: #5e627a;
  height: 40px;
}
.custom-print-button:hover {
  color: #35384d;
  background-color: #dee2e6;
}
</style>

<style>
.custom-toggle {
  color: #5e627a;
  height: 40px;
  background-color: transparent;
}
.custom-toggle:hover {
  color: #35384d;
  background-color: #dee2e6;
}
</style>
